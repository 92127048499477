<template>
  <div class="supplierManagement" v-loading="loading">
    <div class='globle_border content'>
      <header>
        <!-- 选择状态 -->
        <el-select v-model="bidStatusVal" placeholder="选择状态" clearable @change="onCurrentPage">
          <el-option v-for="item in bidStatus" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <!-- 应标资格 -->
        <el-select v-model="bidEligibilityVal" placeholder="应标资格" clearable @change="onCurrentPage">
          <el-option v-for="item in selectData" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <!-- 标期搜索 -->
        <el-select v-model="standardPeriodVal" filterable placeholder="请选择标期搜索" @change="onCurrentPage" clearable>
          <el-option v-for="item in options" :key="item.tendersId" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-input placeholder="请输入标号" suffix-icon="el-icon-search" v-model="searchLabelTxt" :clearable="true"
          @change="labelSearch()"></el-input>
        <el-input placeholder="请输入商品条形码" suffix-icon="el-icon-search" v-model="barCode" clearable
          @change="labelSearch()"></el-input>
      </header>
      <!-- 表格 -->
      <div class="globle_table tableBox">
        <el-table max-height="540" :data="tableData">
          <el-table-column label="序号" min-width="60" align="center">
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }} <!-- 这里可以获取到当前行的索引号 -->
            </template>
          </el-table-column>
          <el-table-column prop="productName" label="商品名称" show-overflow-tooltip min-width="220" align="center">
          </el-table-column>
          <el-table-column prop="barCode" label="商品条形码" show-overflow-tooltip min-width="150" align="center">
          </el-table-column>
          <el-table-column prop="specs" label="规格" show-overflow-tooltip min-width="100" align="center">
          </el-table-column>
          <el-table-column prop="brand" label="品牌" show-overflow-tooltip min-width="100" align="center">
          </el-table-column>
          <el-table-column prop="enterBox" label="入箱数" show-overflow-tooltip min-width="80" align="center">
          </el-table-column>
          <el-table-column prop="factoryName" label="厂家" show-overflow-tooltip min-width="150" align="center">
          </el-table-column>
          <el-table-column prop="bidPeriod" label="标期" show-overflow-tooltip min-width="100" align="center">
          </el-table-column>
          <el-table-column prop="tendersLabel" label="标号" show-overflow-tooltip min-width="100" align="center">
          </el-table-column>
          <el-table-column prop="tendersTime" label="开标时间" show-overflow-tooltip min-width="100" align="center">
          </el-table-column>
          <el-table-column prop="eligibleType" label="应标资格" show-overflow-tooltip min-width="80" align="center">
            <template slot-scope="scope">
              <div>
                {{ scope.row.eligibleType == 2 ? '独立标资格' : '平台资格' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="biddingPrice" label="投标价格" show-overflow-tooltip min-width="80" align="center">
          </el-table-column>
          <el-table-column label="有效时间" show-overflow-tooltip min-width="200" align="center">
            <template slot-scope="scope">
              <div style="display: flex; align-items: center;">
                <div style="flex: 1;">{{ scope.row.effectiveStartTime }}</div>
                <span style="margin: 0 5px;color: gray;">-</span>
                <div style="flex: 1;">{{ scope.row.effectiveEndTime }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="状态" min-width="80" align="center" fixed="right">
            <template slot-scope="scope">
              <div :style="{ color: getStatusColor(scope.row.status) }">
                {{ getStatusText(scope.row.status) }}
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 分页 -->
    <div class="pagination">
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
        layout="prev, pager, next, jumper, sizes, total" :total="totalCount">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      // 招标信息数据
      tableData: [],
      // 分页器
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50],
      totalCount: 0, // 总记录数据
      // 投标状态(1:未评标 2:已中标 3:未中标)
      bidStatus: [
        {
          value: '1',
          label: '未评标'
        }, {
          value: '2',
          label: '已中标'
        }, {
          value: '3',
          label: '未中标'
        },
      ],
      //应标资格
      selectData: [{
        value: '1',
        label: '平台资格'
      }, {
        value: '2',
        label: '独立标资格'
      }],
      //选择标期--一级
      options: [],
      bidStatusVal: '',//投标状态
      bidEligibilityVal: '',//应标资格
      standardPeriodVal: '',//标期
      searchLabelTxt: '',//搜索标号
      barCode: '',//搜索条形码
    }
  },
  created() {
    this.searchLabelTxt = this.$route.query.label || ''
    this.getRequest()
    this.getBidPeriod()
  },
  methods: {
    // 请求
    getRequest() {
      this.loading = true;
      this.$axios.get(this.$api.sendMyTenders, {
        params: {
          status: this.bidStatusVal || null, //投标状态(1:未评标 2:已中标 3:未中标)
          bidPeriod: this.standardPeriodVal || null,//标期
          eligibleType: this.bidEligibilityVal,//类型
          tendersLabel: this.searchLabelTxt || null,//标号
          barCode: this.barCode || null,//条形码
          page: this.currentPage,
          pageSize: this.pageSize,
        }
      }).then((res) => {
        const { code, result } = res.data
        if (code == 100) {
          this.totalCount = result.totalCount
          this.tableData = result.list
          this.loading = false;
        }
      }).catch((err) => {
        console.log(err);
        this.loading = false;
      })

    },
    // 标期列表请求
    getBidPeriod() {
      this.$axios.get(this.$api.sendBidPeriodPage, {
        params: {
          // bidPeriod: this.standardPeriodVal,//标期
          page: this.currentPage,
          pageSize: 99999,
        }
      }).then((res) => {
        const { code, result } = res.data
        if (code == 100) {
          this.options = result.list.map((item) => {
            return {
              tendersId: item.tendersId, // 假设你想要保留tendersId 
              value: item.bidPeriod,
              label: item.bidPeriod
            };
          });
          this.loading = false;
        }
      }).catch((err) => {
        console.log(err);
        this.loading = false;
      })

    },
    // 标号搜索+清除路径上的参数
    labelSearch() {
      this.getRequest()
      if (!this.searchLabelTxt) {
        window.history.replaceState(null, null, this.gelDelURL());
      }
    },
    // 清空路径上携带的参数
    gelDelURL() {
      return window.location.href
        .substring(0, window.location.href.indexOf("?"));
    },
    // 级联选择搜索
    handleChange(val) {
      this.standardPeriodVal = val
      // console.log('级联选择', val);
      this.onCurrentPage()
    },
    // 分页器
    onCurrentPage() {
      this.tableData = []
      this.currentPage = 1
      this.getRequest()
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val
      this.onCurrentPage()
    },
    handleCurrentChange(val) {
      this.tableData = []
      // console.log(`当前页: ${val}`);
      this.currentPage = val
      this.getRequest()
    },


    // 状态文字颜色
    getStatusColor(status) {
      switch (status) {
        case '1':
          return '#069BBC';//未评标
        case '2':
          return '#E35B5A';//已中标
        default:
          return '';//未中标
      }
    },
    // 状态文字显示//投标状态(1:未评标 2:已中标 3:未中标 4:废标(显示未中标))
    getStatusText(status) {
      switch (status) {
        case '1':
          return '未评标';
        case '2':
          return '已中标';
        default:
          return '未中标';
      }
    },
  },
}
</script>

<style scoped lang='scss'>
.supplierManagement {
  .content {
    .ellipsis-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      line-height: 1.5;

    }

    // 头部+搜索
    header {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;

      .el-input,
      .el-select {
        width: 180px;
        margin-left: 10px;
        margin-bottom: 20px;
      }
    }
  }

  .tableBox {
    overflow: auto;


    :deep .el-table--group::after,
    .el-table--border::after,
    .el-table::before {
      content: '';
      position: absolute;
      background-color: #EBEEF5;
      opacity: 0;
    }

    :deep .el-table__body-wrapper::-webkit-scrollbar {
      width: 6px;
      /*滚动条宽度*/
      height: 4px;
      /*滚动条高度*/
    }

  }

  // 操作下拉框内容
  .el-dropdown-link {
    cursor: pointer;
  }

  .el-icon-arrow-down {
    font-size: 12px;

  }

}
</style>
